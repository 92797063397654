<template>
  <div>
    <user-sms v-if="d_wdmr22 && d_wdmr22.id" :p_wdmr22="d_wdmr22" :p_selectedChild="d_selectedChild"></user-sms>
  </div>
</template>

<script>
import store from '@/store';
import PoleStarProject from '@/components/widgets/PoleStarProject';
import UserSms from '@/components/widgets/UserSms';
import { mapGetters } from 'vuex';

export default {
  name: 'PoleStarParent',
  data () {
    return {
      d_wdmr22: '',
      d_selectedChild: { 'label': '', 'key': '' }
    };
  },
  components: {
    PoleStarProject,
    UserSms
  },
  computed: mapGetters({
    help: 'help'
  }),
  created: function () {
    this.d_wdmr22 = JSON.parse(localStorage.getItem('wdm22'));
  },
  mounted () {},
  methods: {},
  watch: {}
};

</script>

<style type="text/css"></style>

