<template>
  <div>
    <b-card no-body header-tag="header">
      <b-card-header header-bg-variant="sedondary" header-text-variant="dark" class="p-1">
        <img src="@/icon/1013986.png" style="width: 2em;"> Sms Sağlayıcı işlemleri
      </b-card-header>
      <b-row style="margin-left: 0px; margin-right: 0px; margin-top: 5px;">
        <b-col cols="12">
          <b-row>
            <b-col sm="12" md="6" lg="5">
              <b-card no-body>
                <b-card-header header-bg-variant="sedondary" header-text-variant="dark" class="p-1">
                  <i class="fa fa-gears"></i> Kayıtlı Sms Sağlayıcılarım
                  <b-button variant="secondary" size="" class="pull-right" @click="f_addNewChildWisdomData('wdm227')" title="yeni sms sağlayıcı ekle">
                    <i class="fa fa-plus"></i>
                  </b-button>
                </b-card-header>
                <template v-for="(wdmr227, wdmr227_ind) in d_wdmr227List">
                  <b-row @click="f_getWdmrData(wdmr227.key, wdmr227.label)" style="margin: 0px;" :style="('wdm227_' + d_selectedwdmr227Data.id) === wdmr227.key ? 'color: green; margin: 0px; cursor: pointer;' : 'margin: 0px; cursor: pointer;'">
                    <b-col sm="12" lg="10">
                      <img src="@/icon/934385.png" style="width: 2em;"> {{ wdmr227.label }}
                    </b-col>
                    <b-col sm="12" lg="2">
                      <b-dropdown class="pull-right" variant="secondary" text="İşlemler" size="sm" v-if="('wdm227_' + d_selectedwdmr227Data.id) === wdmr227.key">
                        <b-dropdown-item @click="f_editChildWdmr('wdm227')">düzenle</b-dropdown-item>
                      </b-dropdown>
                    </b-col>
                  </b-row>
                </template>
              </b-card>
            </b-col>
            <b-col sm="12" md="6" lg="7">
              <template v-if="d_selectedwdmr227Data && d_selectedwdmr227Data.id">
                <b-card no-body>
                  <b-card-header header-bg-variant="sedondary" header-text-variant="dark" class="p-1">
                    {{ d_selectedwdmr227Data.label }}
                  </b-card-header>
                  <div style="overflow-y: auto; overflow-x: hidden; height: 250px;">
                    <wisdom-data-show :change="d_wisdomData.change" :view_mode="d_wisdomData.view_mode" :data="d_selectedwdmr227Data" :data_type="'wdm227'" :option_data="d_wisdomData.option_data['wdm227']"></wisdom-data-show>
                  </div>
                </b-card>
              </template>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <modal v-if="d_wisdomData.show" @close="d_wisdomData.show = false" :width="'750'">
      <h3 slot="header">{{ d_wisdomData.option_data[d_wisdomData.data_type].name.label }}</h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="d_wisdomData.wisdom_data" :data_type="d_wisdomData.data_type" :option_data="d_wisdomData.option_data"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveChildWdm()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_wisdomData.show = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>

<script type="text/javascript">
import {
  default as Modal
} from '@/components/widgets/Modal';
import {
  default as WisdomDataShow
} from '@/components/widgets/WisdomDataShow';
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import GlobalService from '@/services/global';
import { ClsWdmrManage } from "@/services/public/wdmr_manage";
export default {
  name: 'UserSms',
  computed: {},
  props: {
    p_wdmr22: {
      type: Object,
      required: true
    },
    p_selectedSmsProvider: {
      type: Object,
      required: false
    }
  },
  components: {
    WisdomDataModal,
    WisdomDataShow,
    Modal
  },
  data () {
    return {
      d_wdmr227List: [],
      d_selectedwdmr227Data: {},
      d_wisdomData: {
        'view_mode': 'table',
        'change': '0',
        'show': false,
        'wisdom_data': {},
        'data_type': '',
        'wisdom_op': '',
        'option_data': {}
      },
      user: {}
    }
  },
  created () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted () {
    if (this.p_wdmr22) {
      this.f_getWdmr227List();
      this.f_wdmList();
    }
  },
  methods: {
    f_getWdmrData: function (wdmr_key, wdmr_label) {
      if (this.p_selectedSmsProvider) {
        this.p_selectedSmsProvider.label = wdmr_label;
        this.p_selectedSmsProvider.key = wdmr_key;
      }
      // Examples: wdmr_key => wdm7_1, wdm7_2, ......
      let data = { 'key': wdmr_key, 'bucket': 'wisdom' };
      GlobalService.get_one_cb_document(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            this.d_selectedwdmr227Data = resp.data.result;
          } else {
            this.d_selectedwdmr227Data = '';
            console.log('errorr f_getWdmrData : ', resp.data.message);
          }
          this.f_changeFunc();
        });
    },
    f_editChildWdmr: function (data_type) {
      if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('sms_send-sms') !== -1) {
        if (this.d_selectedwdmr227Data.created_by === this.user.id) {
          this.d_wisdomData.wisdom_op = "edit";
          this.d_wisdomData.data_type = data_type;
          this.d_wisdomData.wisdom_data = JSON.parse(JSON.stringify(this.d_selectedwdmr227Data));
          this.d_wisdomData.show = true;
        } else {
          let msg = 'Size ait olmayan sms sağlayıcıyı düzenleyemezsiniz';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Sms sağlayıcı düzenleme işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
      } else {
        let msg = 'Sms sağlayıcı düzenleme işlemi yapabilmeniz için sms gönderim yetkisi almanız gerekmektedir.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Sms sağlayıcı düzenleme işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_saveChildWdm: function () {
      let parent_wdm = '';
      let child_wdm = '';
      let relation_type = 'keys';
      if (this.d_wisdomData.data_type === 'wdm227') {
        parent_wdm = {
          'label': '',
          'key': 'wdm22_' + this.p_wdmr22.id,
          'bucket': 'wisdom',
          'type': 'wdm22'
        };
        child_wdm = {
          'label': '',
          'key': 'wdm_wisdom_wdm227_v2.0',
          'bucket': 'wisdom',
          'type': 'wdm227'
        };
        relation_type = 'keys';
      }
      ClsWdmrManage.save_child_wdmr(this.d_wisdomData, parent_wdm, child_wdm, relation_type)
        .then(resp => {
          // console.log(resp);
          let new_wdmr227 = {
            'label': resp.wdmr.label ? resp.wdmr.label : '',
            'key': 'wdm227_' + resp.wdmr.id
          };
          if (this.d_wisdomData.wisdom_op === 'new') {
            this.d_wdmr227List.push(new_wdmr227);
          } else if (this.d_wisdomData.wisdom_op === 'edit') {
            for (let i in this.d_wdmr227List) {
              if (this.d_wdmr227List[i].key = 'wdm227_' + resp.wdmr.id) {
                this.d_wdmr227List[i].label = resp.wdmr.label ? resp.wdmr.label : '';
                break;
              }
            }
            this.d_selectedwdmr227Data = resp.wdmr;
          }
          this.d_wisdomData.show = false;
          this.f_changeFunc();
        }, resp => {
          alert('error ', resp);
        });
    },
    f_changeFunc: function () {
      if (this.d_wisdomData.change === '0') {
        this.d_wisdomData.change = '1';
      } else {
        this.d_wisdomData.change = '0';
      }
      this.$forceUpdate();
    },
    f_addNewChildWisdomData: function (data_type) {
      if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('sms_send-sms') !== -1) {
        ClsWdmrManage.prepare_new_wisdom_data(this.d_wisdomData, data_type)
        this.d_wisdomData.wisdom_op = 'new';
        this.d_wisdomData.show = true;
      } else {
        let msg = 'Sms gönderim işlemi yapabilmeniz için sms gönderim yetkisi almanız gerekmektedir.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Sms gönderim işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_wdmList: function () {
      let data = {
        'key_list': ['wdm_wisdom_wdm227_v2.0'],
        'bucket': 'option'
      };
      GlobalService.get_multiple_cb_documents(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            for (let i in resp.data.result) {
              this.d_wisdomData.option_data[resp.data.result[i].parent] = resp.data.result[i];
            }
          } else {
            console.log('error ', resp.data.message);
          }
        });
    },
    f_getWdmr227List: function () {
      this.d_wdmr227List = [];
      let parent_wdm = {
        'label': '',
        'key': 'wdm22_' + this.p_wdmr22.id,
        'bucket': 'wisdom',
        'type': 'wdm22'
      };
      let child_wdm = {
        'label': '',
        'bucket': 'wisdom',
        'type': 'wdm227'
      };
      let relation_type = 'keys';
      ClsWdmrManage.get_related_wdmr_list(parent_wdm, child_wdm, relation_type)
        .then(resp => {
          if (resp && resp.list) {
            this.d_wdmr227List = resp.list;
          }
        }, resp => {
          console.log('error ', resp);
        });
    },
  }
}

</script>

